.ScrollAnimation-Stanza-Container {
  padding: 30px 40px;
  border-radius: 20px;
  max-width: 300px;
  text-align: center;
  font-size: 1.2rem;
  margin: 20px;
  position: fixed;
  left: 25%;
  z-index: 2;
  opacity: 0.95;
  white-space: pre-line;

  @media screen and (max-width: 500px) {
    left: 10%;
  }
}

.ScrollAnimation-CenteredAttribution {
  &-Container {
    padding: 10px;
    border-radius: 20px;
    max-width: 300px;
    text-align: center;
    margin: 20px;
    position: fixed;
    margin-left: calc(50% - (300px / 2));
  }

  &-Border {
    border: 1px black solid;
    border-radius: 10px;
    padding: 30px 40px;
    font-size: 1.2rem;
    white-space: pre-line;

    .Back-Button {
      display: block;
      padding-top: 20px;
    }
  }
}

.Form-Container {
  background-color: lighten(#f4d5a1, 10);
  color: black;
  button {
    cursor: pointer;
  }
}

.Form {
  height: calc(100vh - 59px);
  overflow: scroll;
  padding: 20px;
}

.disclaimer {
  padding-bottom: 40px;
}

.Save-Button-Container {
  display: block;
  width: 280px;
  padding-bottom: 10px;
  margin: 0 auto;
  border-top: 1px dotted aliceblue;
}

.Save-Button {
  width: 72px;
  margin: 0 10px;
}

.Green-Button {
  background-color: skyblue;
  border-radius: 3px;
  border: 1px solid blue;

  &:hover {
    background-color: darken(skyblue, 10);
  }
}

.Form-Stanza-Container,
.Form-Image-Container {
  margin: 10px 0;
  border: 1px #625ace solid;
  padding: 4px;
  border-radius: 4px;
  position: relative;

  textarea {
    max-width: 100%;
  }

  div.Label-Input-Group {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    input {
      width: 80px;
    }

    select {
      width: 130px;
    }
  }

  div.Label-Input-Group.hidden {
    display: none;
  }

  div.Label-Input-Group.First {
    margin-top: 5px;
  }

  div.Background-Label-Input {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    input {
      width: 80px;
    }
  }
}

#UrlInput {
  width: 200px;
}

.Title-Input-Group {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.Default-Option {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  margin-top: 2px;
}

.Form-Image-Container .secondary {
  margin-top: -10px;
}

.secondary {
  background-color: red;
  border: none;
  border-radius: 3px;
  color: white;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    background-color: darken(red, 10);
  }
}

.File-Upload {
  margin-left: 10px;
}

.blue {
  color: purple;
}

#HorizontalRangeSlider {
  transform: rotate(180deg);
}
